@font-face {
  font-family: "formom";
  src: url("./zt-formom.ttf");
}

@font-face {
  font-family: "solobae";
  src: url("./selobae.otf");
}
@font-face {
  font-family: "spicyNoodle";
  src: url("./Spicy-Noodles-Regular.otf");
}
@font-face {
  font-family: "duckSpicy";
  src: url("./DuckSpicy-vmWV9.otf");
}

@font-face {
  font-family: "chido";
  src: url("./Chido.otf");
}

@font-face {
  font-family: "oldScientific";
  src: url("./OldScientificIllustration.ttf");
}
@font-face {
  font-family: "futura";
  src: url("./futur.ttf");
}
@font-face {
  font-family: "openSans";
  src: url("./OpenSans_Condensed-Regular.ttf");
}
@font-face {
  font-family: "archive";
  src: url("./Archivo_Condensed-Regular.ttf");
}
@font-face {
  font-family: "testimonia";
  src: url("./Testimonial.ttf");
}
@font-face {
  font-family: "niceHoney";
  src: url("./NiceHoney.otf");
}
@font-face {
  font-family: "milky";
  src: url("./MilkyAgain.otf");
}
@font-face {
  font-family: "alexBrush";
  src: url("./AlexBrush-Regular.ttf");
}
@font-face {
  font-family: "freeHand";
  src: url("./Freehand.ttf");
}
@font-face {
  font-family: "beautique";
  src: url("./BeautiqueDisplay-Black-BF63fd5fa82b67d.otf");
}
@font-face {
  font-family: "prettygirl";
  src: url("./PrettyGirl.ttf");
}
